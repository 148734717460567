import React, { useEffect, useState } from 'react';



function PageTitle() {

    const [userName, setUserName] = useState('');
    useEffect(() => {
        // Retrieve the value from local storage
        const storedUserName = localStorage.getItem('displayName');
        if (storedUserName) {
            setUserName(storedUserName);
        }
    }, []);

    return (
        <>
            <div className="page_title mobinav">

                <div className="page_title-content">
                    <span className='small'>Welcome Back,</span>
                    <p>{userName}</p>
                </div>
            </div>

        </>
    )
}

export default PageTitle;