import React, { useEffect, useState } from 'react';
import { logoutUser } from '../../actions/authAction';
import { Link, useNavigate } from 'react-router-dom';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';



function Header2() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userName, setUserName] = useState('');


    useEffect(() => {
        // Retrieve the value from local storage
        const storedUserName = localStorage.getItem('displayName');
        if (storedUserName) {
            setUserName(storedUserName);
        }
    }, []);
    

    const handleLogout = () => {
        // Dispatch the logoutUser action
        dispatch(logoutUser());
        // Clear the token from local storage
        sessionStorage.removeItem("at");
        sessionStorage.removeItem("ut");
        sessionStorage.removeItem("user_email");
        sessionStorage.removeItem("guid");
        localStorage.removeItem("f_at");
        localStorage.removeItem("displayName");
        // localStorage.removeItem("f_refreshToken");
        navigate("/signin");
    }

    return (
        <>
            <div className="header dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                {/* <Link className="navbar-brand" to={'/'}><img src={require('./../../images/logo.png')} alt="" /></Link> */}

                                <div className="header-search d-flex align-items-center">

                                    <Link className="brand-logo _desktop me-3" href="index.html">
                                        <img src={require('./../../images/logo-with-text.png')} alt="" width='150' />
                                    </Link>
                                    <Link className="brand-logo _mobile me-3" href="index.html">
                                        <img src={require('./../../images/logo-icon.png')} alt="" width='30' />
                                    </Link>

                                </div>

                                <div className="dashboard_log my-2">
                                    <div className="d-flex align-items-center">
                                        {/* <form className='me-3'>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search" />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2"><i
                                                    className="fa fa-search text-white"></i></span>
                                            </div>
                                        </div>
                                    </form> */}
                                        <DropdownButton
                                            title="My Account"
                                            className="profile_log">
                                            <div className="page_title-content dropdown-item flex-column align-items-start">
                                                <span className='small'>Welcome Back,</span>
                                                <p className='m-0 text-primary fw-bold'>{userName}</p>
                                            </div>
                                            <Link to={'/settings'} className="dropdown-item">
                                                <i className="mdi mdi-cogs"></i> Settings
                                            </Link>
                                            {/* <Link to={'/settings-security'} className="dropdown-item">
                                                <i className="la la-shield-alt"></i> Security
                                            </Link> */}
                                            <Link to={'/payment-method'} className="dropdown-item">
                                                <i className="mdi mdi-credit-card"></i> Payment Method
                                            </Link>
                                            <Link to={'/external-wallet'} className="dropdown-item">
                                            <i className="mdi mdi-wallet"></i> External Wallet
                                            </Link>

                                            {/* <Link to={'/lock'} className="dropdown-item">
                                                <i className="la la-phone"></i> Support
                                            </Link> */}
                                            <div onClick={handleLogout} className="dropdown-item logout light-bg m-0 rounded-0">
                                                <i className="mdi mdi-logout"></i> Logout
                                            </div>
                                        </DropdownButton>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header2;