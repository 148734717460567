import { adminTypes } from "../constants/admintypes";

const initialState = {
  signInSuccess: {},
  signInFailed: {},
  getAllCustomersList: {},
  getAllCutomersListFailed: {},
  getCustomerData: {},
  getCustomerDataFailed: {},
  getSingleCustomerData: {},
  getSingleCustomerDataFailed: {},
  getExternalBankAccounts: {},
  getExternalBankAccountsFailed: {},
  getExternalWallets: {},
  getExternalWalletsFailed: {},
  getAccounts: {},
  getAccountsFailed: {},
  getQuotes: {},
  getQuotesFailed: {},
  getTrades: {},
  getTradesFailed: {},
  getTransfers: {},
  getTransfersFailed: {},
};

export function adminReducer(state = initialState, action) {
  switch (action.type) {
    case adminTypes.GET_ALL_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        getAllCustomersList: action.payload,
      };
    case adminTypes.GET_ALL_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        getAllCustomersList: [],
        getAllCutomersListFailed: action.payload,
      };
    case adminTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        signInSuccess: action.payload,
      };
    case adminTypes.POST_LOGIN_FAILURE:
      return {
        ...state,
        signInFailed: action.payload,
        signInSuccess: {},
      };
    case adminTypes.GET_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        getCustomerData: action.payload,
      };
    case adminTypes.GET_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        getCustomerDataFailed: action.payload,
        getCustomerData: {},
      };
    case adminTypes.GET_SINGLE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        getSingleCustomerData: action.payload,
      };
    case adminTypes.GET_SINGLE_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        getSingleCustomerDataFailed: action.payload,
        getSingleCustomerData: {},
      };
    case adminTypes.GET_EXTERNAL_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        getExternalBankAccounts: action.payload,
      };
    case adminTypes.GET_EXTERNAL_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        getExternalBankAccountsFailed: action.payload,
        getExternalBankAccounts: {},
      };
    case adminTypes.GET_EXTERNAL_WALLETS_SUCCESS:
      return {
        ...state,
        getExternalWallets: action.payload,
      };
    case adminTypes.GET_EXTERNAL_WALLETS_FAILURE:
      return {
        ...state,
        getExternalWalletsFailed: action.payload,
        getExternalWallets: {},
      };
    case adminTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        getAccounts: action.payload,
      };
    case adminTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        getAccountsFailed: action.payload,
        getAccounts: {},
      };
    case adminTypes.GET_QUOTES_SUCCESS:
      return {
        ...state,
        getQuotes: action.payload,
      };
    case adminTypes.GET_QUOTES_FAILURE:
      return {
        ...state,
        getQuotesFailed: action.payload,
        getQuotes: {},
      };
    case adminTypes.GET_TRANSFERS_SUCCESS:
      return {
        ...state,
        getTransfers: action.payload,
      };
    case adminTypes.GET_TRANSFERS_FAILURE:
      return {
        ...state,
        getTransfersFailed: action.payload,
        getTransfers: {},
      };
    case adminTypes.GET_TRADES_SUCCESS:
      return {
        ...state,
        getTrades: action.payload,
      };
    case adminTypes.GET_TRADES_FAILURE:
      return {
        ...state,
        getTradesFailed: action.payload,
        getTrades: {},
      };
    case adminTypes.GET_IDENTITY_VERIFICATIONS_SUCCESS:
      return {
        ...state,
        getIdentityVerifications: action.payload,
      };
    case adminTypes.GET_IDENTITY_VERIFICATIONS_FAILURE:
      return {
        ...state,
        getIdentityVerificationsFailed: action.payload,
        getIdentityVerifications: {},
      };
    default:
      return state;
  }
}
