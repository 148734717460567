import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCommonError, resetCommonSuccess } from '../../actions/customerActions';


function Notification() {
    const dispatch = useDispatch();

    const errorMessageshown = useSelector(state => state.customerReducer.commonErrorMessage);
    const successMessageshown = useSelector(state => state.customerReducer.commonSuccessMessage);
    const [msgClass, setMsgClass] = useState('')
    const [timeoutIds, setTimeoutIds] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showClass, setShowClass] = useState('')

    let timeouts = [];
    let firstTimeout;
    let secondTimeout;
    let thirdTimeout;
    let fourthTiemout;

    useEffect(() => {
        let timeoutId;

        if ((successMessageshown && successMessageshown !== '') || (errorMessageshown && errorMessageshown !== '')) {
            if (errorMessageshown !== '') {
                // console.log(11);
                if(errorMessageshown === "Signature has expired"){
                    setErrorMessage("Your session has expired")
                }else {
                    setErrorMessage(errorMessageshown);
                }
                dispatch(resetCommonError());
                setSuccessMessage('');
                // Set a timeout to clear the error message after 5 seconds
                // timeoutId = setTimeout(() => {
                //     setErrorMessage('');
                // }, 7 * 1000);
            } else {
                // console.log(22);
                setSuccessMessage(successMessageshown);
                dispatch(resetCommonSuccess());
                setErrorMessage('');
                // Set a timeout to clear the success message after 5 seconds
                // timeoutId = setTimeout(() => {
                //     setSuccessMessage('');
                // }, 7 * 1000);
            }
            if (timeoutIds.length > 0) {
                timeoutIds.forEach(timeoutId => {
                    clearTimeout(timeoutId);
                });
                setTimeoutIds([]);
            }
            firstTimeout = setTimeout(() => {
                setShowClass('show')
            }, 200)
            setTimeoutIds([firstTimeout]);
            secondTimeout = setTimeout(() => {
                setMsgClass('show')
            }, 750)

            setTimeoutIds(timeoutIds => [...timeoutIds, secondTimeout]);

            thirdTimeout = setTimeout(() => {
                setShowClass('hide')
                setMsgClass('hide')
            }, 4500)
            setTimeoutIds(timeoutIds => [...timeoutIds, thirdTimeout]);

            fourthTiemout = setTimeout(() => {
                // console.log("Not cleared")
                // console.log(errorMessage)
                if (successMessage !== '') {
                    setSuccessMessage('')
                    setShowClass('')
                    setMsgClass('')
                } else if (errorMessage !== '') {
                    // console.log("1")
                    setErrorMessage('')
                    setShowClass('')
                    setMsgClass('')
                }
                setTimeoutIds([]);
            }, 5000)
            setTimeoutIds(timeoutIds => [...timeoutIds, fourthTiemout]);

        }
    }, [errorMessageshown, successMessageshown]);

    useEffect(() => {
        clearNestedTimeout()
    }, [successMessage, errorMessage])

    const clearNestedTimeout = () => {
        timeouts.forEach(timeout1 => {
            clearTimeout(timeout1)
        })
    }

    return (
        <>
            {(successMessage !== '')  ? (
                <div className={`alert alert-success cf-alert ${showClass} alert-top chcro`} role="alert">
                    {msgClass === 'show' ? (
                        <div className="svgicon">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path circle" fill="none" stroke="#06562f" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <polyline className="path check" fill="none" stroke="#06562f" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                            </svg>
                        </div>
                    ) : ""}
                    <span className="alertmsg">{successMessage}</span>
                </div>
            ) : ""}

            {(errorMessage !== '') ? (
                <div className={`alert alert-danger cf-alert ${showClass} alert-top chcro`} role="alert">
                    {msgClass === 'show' ? (
                        <div className="svgicon">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path circle" fill="none" stroke="#ff2121" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <line className="path line" fill="none" stroke="#ff2121" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                <line className="path line" fill="none" stroke="#ff2121" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                            </svg>
                        </div>
                    ) : ""}
                    <span className="alertmsg">{errorMessage}</span>
                </div>
            ) : ""}

        </>
    )
}

export default Notification;