import { signInWithEmailAndPassword, onAuthStateChanged, getUserData, getAuth } from 'firebase/auth';
import { types } from "../store/constants/types"; // Import your action types
import apiSignUp from "../services/apiSignUp";
import apiAdminWithJwt from "../services/apiAdminWithJwt";

export const signUpUser = (userData) => {
  return (dispatch) => {
    apiSignUp
      .post("/customers/signup", userData)
      .then((res) => {
        dispatch({ type: types.POST_SIGNUP_SUCCESS, payload: res });
      })
      .catch((error) => {
        dispatch({ type: types.POST_SIGNUP_FAILURE, payload: error });
      });
  };
};

export const resetSignUpUser = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_SIGNUP_SUCCESS, payload: [] });
  };
};
export const resetSignUpUserFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_SIGNUP_FAILURE, payload: {} });
  };
};

export const signInUser = (email,pass) => {
  return (dispatch) => {
    signInWithEmailAndPassword(getAuth(),email,pass)
    .then((response) => {
      let obj = {
        statusCode : 200,
        data : response
      }
      dispatch({ type: types.POST_SIGNIN_SUCCESS, payload: obj });
    })
    .catch((error) => {
      dispatch({ type: types.POST_SIGNIN_FAILURE, payload: error });
    });
  };
};


export const resetSignInUser = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_SIGNIN_SUCCESS, payload: {} });
  };
};

export const resetSignInUserFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_SIGNIN_FAILURE, payload: {} });
  };
};
export const generateCustomerToken = (userData) => {
  return (dispatch) => {
    apiSignUp
      .post("/customers/generate-customer-token", userData)
      .then((res) => {
        dispatch({ type: types.POST_CUSTOMERTOKEN_SUCCESS, payload: res });
      })
      .catch((error) => {
        dispatch({ type: types.POST_CUSTOMERTOKEN_FAILURE, payload: error });
      });
  };
};

export const generateEmailLink = (usrEmail) => {
  return (dispatch) => {
    apiSignUp
      .post("/customers/generate-email-link", {email :usrEmail})
      .then((res) => {
        dispatch({ type: types.POST_EMAIL_LINK_SUCCESS, payload: res });
      })
      .catch((error) => {
        dispatch({ type: types.POST_EMAIL_LINK_FAILURE, payload: error });
      });
  };
};
export const resetEmailSentFailed = () => {
  return dispatch => {
     dispatch({type : types.POST_EMAIL_LINK_FAILURE, payload : {}})
  }
}

export const resetCustomerToken = () => {
  return dispatch => {
     dispatch({type : types.POST_CUSTOMERTOKEN_SUCCESS, payload : []})
  }
}

export const resetCustomerTokenFailed = () => {
  return dispatch => {
     dispatch({type : types.POST_CUSTOMERTOKEN_FAILURE, payload : {}})
  }
}

export const landingPageLivePrice = () => {
  return (dispatch) => {
    apiSignUp
      .get("/customers/landing-live-price")
      .then((res) => {
        dispatch({ type: types.GET_LANDING_PAGE_PRICE_SUCCESS, payload: res.data.data });
      })
      .catch((error) => {
        dispatch({ type: types.GET_LANDING_PAGE_PRICE_FAILDED, payload: error });
      });
  };
};

export const resetLandingPageLivePrice = () => {
  return dispatch => {
     dispatch({type : types.GET_LANDING_PAGE_PRICE_SUCCESS, payload : []})
  }
}

export const logoutUser = () => {
  return (dispatch) => dispatch({ type: types.LOGOUT });
};

export const updateTheme = (value)=> {
  return (dispatch) => {
    if (value === true) {
      localStorage.setItem("theme", `${'1'}`);
      dispatch({
          type: types.UPDATE_THEME,
          payload: "1",
      })
    } else {
      localStorage.setItem("theme", `${'0'}`);
      dispatch({
          type: types.UPDATE_THEME,
          payload: "0",
      })
    }
  }
};

export const themeInitialValue = (value) => {
  return (dispatch) => {
    if(value === '1'){
      dispatch({
        type: types.UPDATE_THEME,
        payload: "1",
    })
   } else {
      dispatch({
        type: types.UPDATE_THEME,
        payload: "0",
    })
   }
  }
};

export const resetPasswordLink =(usrEmail)=> {
  return (dispatch)=> {
    apiSignUp
      .post("/customers/reset-password", {email :usrEmail})
      .then((res) => {
        dispatch({ type: types.POST_PASSWORD_RESET_LINK_SUCCESS, payload: res });
        return res;
      })
      .catch((error) => {
        dispatch({ type: types.POST_PASSWORD_RESET_LINK_FAILED, payload: error });
      });
  }
}

export const resetPasswordSentFailed = () => {
  return dispatch => {
     dispatch({type : types.POST_PASSWORD_RESET_LINK_FAILED, payload : {}})
  }
}

export const updatePassword = (userEmail, newPassword) => {
  return (dispatch) => {
    const usertoken = sessionStorage.getItem("ut");
    const apiWithToken = apiAdminWithJwt(usertoken)
    apiWithToken.post("/customers/update-password",{email: userEmail, password: newPassword})
    .then((res) => {
      dispatch({ type: types.PASSWORD_UPDATE_SUCCESS, payload: res });
      return res;
    })
    .catch((error) => {
      dispatch({ type: types.PASSWORD_UPDATE_FAILED, payload: error });
    });
  }
}

export const resetUpdatePassword = () => {
  return dispatch => {
    dispatch({type : types.PASSWORD_UPDATE_SUCCESS, payload : {}})
 }
}

export const resetUpdatePasswordFailed = () => {
  return dispatch => {
    dispatch({type : types.PASSWORD_UPDATE_FAILED, payload : {}})
 }
}

export const createOtp =(usrEmail, customerGuid)=> {
  return (dispatch)=> {
    const usertoken = sessionStorage.getItem("ut");
    const apiWithToken = apiAdminWithJwt(usertoken);
    apiWithToken
      .post("/customers/create-otp", {email :usrEmail, customer_guid: customerGuid? customerGuid : ''})
      .then((res) => {
        dispatch({ type: types.CREATE_OTP_SUCCESS, payload: res });
        return res;
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: types.CREATE_OTP_FAILED, payload: error });
      });
  }
}

export const createOtpCrypto =(usrEmail, customerGuid)=> {
  return (dispatch)=> {
    const usertoken = sessionStorage.getItem("ut");
    const apiWithToken = apiAdminWithJwt(usertoken);
    apiWithToken
      .post("/customers/create-otp", {email :usrEmail, customer_guid: customerGuid? customerGuid : ''})
      .then((res) => {
        dispatch({ type: types.CREATE_OTP_CRYPTO_SUCCESS, payload: res });
        return res;
      })
      .catch((error) => {
        console.log(error)
        dispatch({ type: types.CREATE_OTP_CRYPTO_FAILED, payload: error });
      });
  }
}

export const resetCreateOtp = () => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_OTP_SUCCESS, payload: [] });
  };
};
export const resetCreateOtpFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_OTP_FAILED, payload: [] });
  };
};
export const resetCreateOtpCrypto = () => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_OTP_CRYPTO_SUCCESS, payload: [] });
  };
};
export const resetCreateOtpCryptoFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_OTP_CRYPTO_FAILED, payload: [] });
  };
};

export const verifyOtp =(usrEmail, enteredOtp, customerGuid)=> {
  return (dispatch)=> {
    const usertoken = sessionStorage.getItem("ut");
    const apiWithToken = apiAdminWithJwt(usertoken);
    apiWithToken
      .post("/customers/verify_otp", {email : usrEmail, otp : enteredOtp ,customer_guid: customerGuid? customerGuid : ''})
      .then((res) => {
        dispatch({ type: types.VERIFY_OTP_SUCCESS, payload: res });
        return res;
      })
      .catch((error) => {
        dispatch({ type: types.VERIFY_OTP_FAILED, payload: error });
      });
  }
}
export const verifyOtpCrypto =(usrEmail, enteredOtp, customerGuid)=> {
  return (dispatch)=> {
    const usertoken = sessionStorage.getItem("ut");
    const apiWithToken = apiAdminWithJwt(usertoken);
    apiWithToken
      .post("/customers/verify_otp", {email : usrEmail, otp : enteredOtp ,customer_guid: customerGuid? customerGuid : ''})
      .then((res) => {
        dispatch({ type: types.VERIFY_OTP_CRYPTO_SUCCESS, payload: res });
        return res;
      })
      .catch((error) => {
        dispatch({ type: types.VERIFY_OTP_CRYPTO_FAILED, payload: error });
      });
  }
}

export const resetVerifyOtp = () => {
  return (dispatch) => {
    dispatch({ type: types.VERIFY_OTP_SUCCESS, payload: [] });
  };
};
export const resetVerifyOtpFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.VERIFY_OTP_FAILED, payload: [] });
  };
};
export const resetVerifyOtpCrypto = () => {
  return (dispatch) => {
    dispatch({ type: types.VERIFY_OTP_CRYPTO_SUCCESS, payload: [] });
  };
};
export const resetVerifyOtpCryptoFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.VERIFY_OTP_CRYPTO_FAILED, payload: [] });
  };
};

export const postUserInformation = (userEmail) => {
  return (dispatch) => {
    const usertoken = sessionStorage.getItem("ut");
    const apiWithToken = apiAdminWithJwt(usertoken);
    apiWithToken.post("/customers/user-detail",{email: userEmail})
    .then((res) => {
      dispatch({ type: types.USER_DETAIL_SUCCESS, payload: res });
      return res;
    })
    .catch((error) => {
      dispatch({ type: types.USER_DETAIL_FAILED, payload: error });
    });
  }
}

export const resetPostUserInformation = () => {
  return dispatch => {
    dispatch({type : types.USER_DETAIL_SUCCESS, payload : {}})
 }
}
 
export const resetPostUserInformationFailed = () => {
  return dispatch => {
    dispatch({type : types.USER_DETAIL_FAILED, payload : {}})
 }
}
export const EmailNotification = (data) => {
  return (dispatch) => {
    apiSignUp.post("/customers/email-notification",{detail:data })
    .then((res) => {
      dispatch({ type: types.EMAIL_NOTIFICATION_SUCCESS, payload: res });
      return res;
    })
    .catch((error) => {
      dispatch({ type: types.EMAIL_NOTIFICATION_FAILED, payload: error });
    });
  }
}

export const resetEmailNotification = () => {
  return dispatch => {
    dispatch({type : types.EMAIL_NOTIFICATION_SUCCESS, payload : {}})
 }
}
 
export const resetEmailNotificationFailed = () => {
  return dispatch => {
    dispatch({type : types.EMAIL_NOTIFICATION_FAILED, payload : {}})
 }
}
export const regenerateCustomerToken = (data,token) => {
  return (dispatch) => {
    const apiWithToken = apiAdminWithJwt(token)
    apiWithToken.post("/customers/regenerate-customer-token",data)
    .then((res) => {
      dispatch({ type: types.REGENERATE_CUSTOMER_TOKEN_SUCCESS, payload: res });
      return res;
    })
    .catch((error) => {
      dispatch({ type: types.REGENERATE_CUSTOMER_TOKEN_FAILED, payload: error });
    });
  }
}

export const resetregenerateCustomerToken = () => {
  return dispatch => {
    dispatch({type : types.REGENERATE_CUSTOMER_TOKEN_SUCCESS, payload : {}})
 }
}
 
export const resetregenerateCustomerTokenFailed = () => {
  return dispatch => {
    dispatch({type : types.REGENERATE_CUSTOMER_TOKEN_FAILED, payload : {}})
 }
}

export const checkWallet = (data)=> {
  return (dispatch) => {
    apiSignUp
      .post('/customers/check-wallet', data)
      .then((res) => {
        dispatch({
          type: types.CHECK_WALLET_SUCCESS,
          payload: res?.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: types.CHECK_WALLET_FAILED,
          payload: error?.response?.data,
        });
      });
  };
}

export const resetCheckWalletSuccess = () => {
  return (dispatch) => {
    dispatch({ type: types.CHECK_WALLET_SUCCESS, payload: {} });
  };
};

export const resetCheckWalletFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.CHECK_WALLET_FAILED, payload: {} });
  };
};
