import { combineReducers } from 'redux';
import { customerReducer } from './reducers/customer-reducer';
import { adminReducer } from "./reducers/admin-reducer";
import { authReducer } from './reducers/auth-reducer';
import { types } from './constants/types';

// Combine your individual reducers
const rootReducer = combineReducers({
 customerReducer,
 adminReducer,
 authReducer,
});

const appReducer = (state, action) => {
  if (action.type === types.LOGOUT) {
    state = undefined;
  }
  return rootReducer(state, action);
};

export default appReducer;
