import React from 'react'
// import './css/style.css'
// import './css/style-two.css'
// import './css/tce.css'
import Index from './jsx'
import Notification from './jsx/layout/Notification'
// import { Lines } from 'react-preloaders';
import { useSelector } from 'react-redux'
// import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
// import TagManager from 'react-gtm-module';

// if (process.env.REACT_APP_ENV === 'prod') {
//   ReactGA.initialize('G-QTCKEHY0YQ')
// }

// if (process.env.NODE_ENV === 'prod') {
//   const tagManagerArgs = {
//     gtmId: 'GTM-P5S3SWQW',
//   };
//   TagManager.initialize(tagManagerArgs);
// }

function App() {
  const themeValue = useSelector((state) => state.authReducer.themeValue)
  return (
    <div className={`${themeValue === '1' ? 'dark-theme App' : 'App'}`}>
      <Helmet>
        {/* Organization Schema */}
        <script type='application/ld+json'>
          {`
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "The Crypto Exchange",
            "url": "https://thecryptoexchange.ca/",
            "logo": "../public/logo-with-text.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "519-996-7471",
              "contactType": "customer service"
            },
            "sameAs": "https://x.com/CryptoBoras?t=8KPSKy2LMi1w61YPIqEYEA&s=35"
          `}
        </script>

        {/* Breadcrumb Schema */}
        <script type='application/ld+json'>
          {`
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://thecryptoexchange.ca/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Wallet",
              "item": "https://thecryptoexchange.ca/wallet"  
            },{
              "@type": "ListItem", 
              "position": 3, 
              "name": "OTC",
              "item": "https://thecryptoexchange.ca/otc"  
            },{
              "@type": "ListItem", 
              "position": 4, 
              "name": "Services",
              "item": "https://thecryptoexchange.ca/services"  
            },{
              "@type": "ListItem", 
              "position": 5, 
              "name": "Learn",
              "item": "https://thecryptoexchange.ca/learn"  
            },{
              "@type": "ListItem", 
              "position": 6, 
              "name": "Blog",
              "item": "https://blog.thecryptoexchange.ca/"  
            },{
              "@type": "ListItem", 
              "position": 7, 
              "name": "About",
              "item": "https://thecryptoexchange.ca/about"  
            },{
              "@type": "ListItem", 
              "position": 8, 
              "name": "Terms and Conditions",
              "item": "https://thecryptoexchange.ca/terms-and-conditions"  
            },{
              "@type": "ListItem", 
              "position": 9, 
              "name": "Privacy and Policy",
              "item": "https://thecryptoexchange.ca/privacy-policy"  
            }]
          `}
        </script>

        {/* NAP Schema */}
        <script type='application/ld+json'>
          {`
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "The Crypto Exchange",
            "image": "../public/logo-with-text.png",
            "@id": "",
            "url": "https://thecryptoexchange.ca/",
            "telephone": "519-996-7471",
            "priceRange": "$",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "3020 Dougall Ave Unit 8, Windsor, Ontario, Canada N9E 1S4.",
              "addressLocality": "Ontario",
              "addressRegion": "ON",
              "postalCode": "N9E 1S4",
              "addressCountry": "CA"
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
              ],
              "opens": "09:00",
              "closes": "05:00"
            },
            "sameAs": "https://x.com/CryptoBoras?t=8KPSKy2LMi1w61YPIqEYEA&s=35" 
          `}
        </script>
      </Helmet>
      <Notification />
      <Index />
    </div>
  )
}

export default App
