export const adminTypes = {
  POST_LOGIN_SUCCESS: "POST_LOGIN_SUCCESS",
  POST_LOGIN_FAILURE: "POST_LOGIN_FAILURE",
  GET_ALL_CUSTOMERS_LIST_SUCCESS: "GET_ALL_CUSTOMERS_LIST_SUCCESS",
  GET_ALL_CUSTOMER_LIST_FAILURE: "GET_ALL_CUSTOMER_LIST_FAILURE",
  GET_CUSTOMER_DATA_SUCCESS: "GET_CUSTOMER_DATA_SUCCESS",
  GET_CUSTOMER_DATA_FAILURE: "GET_CUSTOMER_DATA_FAILURE",
  GET_SINGLE_CUSTOMER_DATA_SUCCESS: "GET_SINGLE_CUSTOMER_DATA_SUCCESS",
  GET_SINGLE_CUSTOMER_DATA_FAILURE: "GET_SINGLE_CUSTOMER_DATA_FAILURE",
  GET_EXTERNAL_BANK_ACCOUNTS_SUCCESS: "GET_EXTERNAL_BANK_ACCOUNTS_SUCCESS",
  GET_EXTERNAL_BANK_ACCOUNT_FAILURE: "GET_EXTERNAL_BANK_ACCOUNT_FAILURE",
  GET_EXTERNAL_WALLETS_SUCCESS: "GET_EXTERNAL_WALLETS_SUCCESS",
  GET_EXTERNAL_WALLETS_FAILURE: "GET_EXTERNAL_WALLETS_FAILURE",
  GET_ACCOUNTS_SUCCESS: "GET_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_FAILURE: "GET_ACCOUNTS_FAILURE",
  GET_QUOTES_SUCCESS: "GET_QUOTES_SUCCESS",
  GET_QUOTES_FAILURE: "GET_QUOTES_FAILURE",
  GET_TRADES_SUCCESS: "GET_TRADES_SUCCESS",
  GET_TRADES_FAILURE: "GET_TRADES_FAILURE",
  GET_TRANSFERS_SUCCESS: "GET_TRANSFERS_SUCCESS",
  GET_TRANSFERS_FAILURE: "GET_TRANSFERS_FAILURE", 
  GET_IDENTITY_VERIFICATIONS_SUCCESS: "GET_IDENTITY_VERIFICATIONS_SUCCESS",
  GET_IDENTITY_VERIFICATIONS_FAILURE: "GET_IDENTITY_VERIFICATIONS_FAILURE",
}