import React, { useState, useEffect } from "react";
import Gifloader from "../layout/gifloader";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import PageTitle from "../element/page-title";

function EmptyLayout() {


    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />
            <div className="content-body dash-main exchange">
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12" style={{height:"90vh"}}>
                            <div className="card fadeInDown d-flex justify-content-center align-items-center">
                              <Gifloader/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmptyLayout;
