import React from 'react';

function Gifloader() {

    return (
        <>
            <div className='text-center gifloader'>
                <img src={require("../../images/tce-loader.gif")} alt="" />
            </div>
        </>
    )
}

export default Gifloader;