// apiSignUp.js
import axios from 'axios';
import { config } from '../config';

const apiSignUp = axios.create({
  baseURL: config.BACKEND_API,
  headers: {
    'Content-Type': 'application/json',
    // Add any common headers here if required
  },
});

export default apiSignUp;
