import { types } from "../constants/types";

const initialState = {
  signUpSuccess: {},
  signUpFailed: "",
  signInSuccess: {},
  signInFailed: "",
  createOtpData: {},
  createOtpFailed: "",
  verifyOtpData: {},
  verifyOtpFailed: "",
  customerTokenSuccess: {},
  customerTokenFailed: "",
  landingLivePriceSuccess: {},
  landingLivePriceFailed: "",
  postEmailLinkData:{},
  postEmailLinkFailed:{},
  postResetPasswordLinkData:{},
  postResetPasswordLinkFailed:{},
  token: null, // Add a 'token' field to the initial state
  themeValue:'0',
  passwordUpdateSuccess:{},
  passwordUpdateFailed:{},
  userInformationData:{},
  userInformationFailed:{},
  postEmailNotificationData:{},
  postEmailNotificationFailed:{},
  regenerateCustomerTokenData:{},
  regenerateCustomerTokenFailed:{},
  checkWalletData:{},
  checkWalletFailedData:{}
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.POST_SIGNUP_SUCCESS:
      return {
        ...state,
        signUpSuccess: action.payload,
      };
    case types.POST_SIGNUP_FAILURE:
      return {
        ...state,
        signUpFailed: action.payload,
        signUpSuccess:{}
      };
    case types.POST_SIGNIN_SUCCESS:
      return {
        ...state,
        signInSuccess: action.payload,
        // token: action.payload, // Set the token in the state when signing in successfully
      };
    case types.POST_SIGNIN_FAILURE:
      return {
        ...state,
        signInFailed: action.payload,
        signInSuccess:{},
      };
    case types.CREATE_OTP_SUCCESS:
      return {
        ...state,
        createOtpData: action.payload,
      };
    case types.CREATE_OTP_FAILED:
      return {
        ...state,
        createOtpFailed: action.payload,
        createOtpData:{},
      };
    case types.CREATE_OTP_CRYPTO_SUCCESS:
      return {
        ...state,
        createOtpCryptoData: action.payload,
      };
    case types.CREATE_OTP_CRYPTO_FAILED:
      return {
        ...state,
        createOtpCryptoFailed: action.payload,
        createOtpCryptoData:{},
      };
    case types.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtpData: action.payload,
      };
    case types.VERIFY_OTP_FAILED:
      return {
        ...state,
        verifyOtpFailed: action.payload,
        verifyOtpData:{},
      };
    case types.VERIFY_OTP_CRYPTO_SUCCESS:
      return {
        ...state,
        verifyOtpCryptoData: action.payload,
      };
    case types.VERIFY_OTP_CRYPTO_FAILED:
      return {
        ...state,
        verifyOtpCryptoFailed: action.payload,
        verifyOtpCryptoData:{},
      };
    case types.POST_CUSTOMERTOKEN_SUCCESS:
      return {
        ...state,
        customerTokenSuccess: action.payload,
      };
    case types.POST_CUSTOMERTOKEN_FAILURE:
      return {
        ...state,
        customerTokenFailed: action.payload,
      };
    case types.GET_LANDING_PAGE_PRICE_SUCCESS:
      return {
        ...state,
        landingLivePriceSuccess: action.payload,
      };
    case types.GET_LANDING_PAGE_PRICE_FAILDED:
      return {
        ...state,
        landingLivePriceFailed: action.payload,
      };

    case types.POST_EMAIL_LINK_SUCCESS :
      return {
        ...state,
        postEmailLinkData: action.payload,
      }
    case types.POST_EMAIL_LINK_FAILURE :
      return {
        ...state,
        postEmailLinkFailed: action.payload,
        postEmailLinkData:{},
      }
    case types.POST_PASSWORD_RESET_LINK_SUCCESS :
      return {
        ...state,
        postResetPasswordLinkData: action.payload,
      }
    case types.POST_PASSWORD_RESET_LINK_FAILED :
      return {
        ...state,
        postResetPasswordLinkFailed: action.payload,
        postResetPasswordLinkData:{},
      }
    // case types.LOGOUT:
    //   return {
    //     ...state,
    //     signUpSuccess: {},
    //     signUpFailed: "",
    //     signInSuccess: {},
    //     signInFailed: "",
    //     token: null,
    //     // ... any other state fields you want to reset on logout ...
    //   };
    case types.UPDATE_THEME:
      return{
        ...state,
        themeValue:action.payload,
      }
    case types.PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        passwordUpdateSuccess:action.payload,
      }
    case types.PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        passwordUpdateFailed:action.payload,
      }
    case types.USER_DETAIL_SUCCESS:
      return {
        ...state,
        userInformationData: action.payload,
      };
    case types.USER_DETAIL_FAILED:
      return {
        ...state,
        userInformationData: {},
        userInformationFailed: action.payload,
      };
    case types.EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        postEmailNotificationData: action.payload,
      };
    case types.EMAIL_NOTIFICATION_FAILED:
      return {
        ...state,
        postEmailNotificationData: {},
        postEmailNotificationFailed: action.payload,
      };
    case types.REGENERATE_CUSTOMER_TOKEN_SUCCESS:
      return {
        ...state,
        regenerateCustomerTokenData: action.payload,
      };
    case types.REGENERATE_CUSTOMER_TOKEN_FAILED:
      return {
        ...state,
        regenerateCustomerTokenData: {},
        regenerateCustomerTokenFailed: action.payload,
      };
    case types.CHECK_WALLET_SUCCESS:
      return {
        ...state,
        checkWalletData: action.payload,
      };
    case types.CHECK_WALLET_FAILED:
      return {
        ...state,
        checkWalletFailedData: action.payload,
        checkWalletData: {},
      };
    default:
      return state;
  }
}
