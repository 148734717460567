import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import appReducer from './rootReducers';

const loggerMiddleware = createLogger();

export const store = configureStore({
  reducer: appReducer, // Use the combined reducer
  middleware: process.env.REACT_APP_ENV === 'local' ? [thunkMiddleware, loggerMiddleware] : [thunkMiddleware],
});
