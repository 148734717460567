import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { commonError } from "../../../actions/customerActions";
import { Navigate } from "react-router-dom";


export const AdminAuthenticateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const jwtToken = sessionStorage.getItem("a_at");

  if (jwtToken) {
    // Check if the token has expired
    const tokenData = JSON.parse(atob(jwtToken.split(".")[1])); // Decode the payload
    const tokenExpiration = tokenData.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();

    if (tokenExpiration < currentTime) {
      sessionStorage.removeItem("a_at");
      localStorage.removeItem("email");
      dispatch(commonError("Session has ended, Please login again."))
      return <Navigate to="/admin/customer" />;
    }
  }
  return <div>{children}</div>;
};

export const AdminRoute = ({ children }) => {
  const dispatch = useDispatch();
  const jwtToken = sessionStorage.getItem("a_at");

  // If there is no token redirect to signin page 
  if (!jwtToken) {
    dispatch(commonError("Session has expired, Please login again."));
    return <Navigate to="/admin/signin" />;
  }

  if (jwtToken) {
    // Check if the token has expired
    const tokenData = JSON.parse(atob(jwtToken.split(".")[1])); // Decode the payload
    const tokenExpiration = tokenData.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();

    if (tokenExpiration < currentTime) {
      sessionStorage.removeItem("a_at");
      localStorage.removeItem("email");
      dispatch(commonError("Session has ended, Please login again."))
      return <Navigate to="/admin/signin" />
    }
  }
  return <div>{children}</div>;
};
