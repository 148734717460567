import CryptoJS from "crypto-js";
import bitcoinLogo from "../images/bitcoin.png";
import ethereumLogo from "../images/ethereum.png";
import bitcoincashLogo from "../images/bch.png";
import litecoinLogo from "../images/LTC.png";
import cadLogo from "../images/canada.png";

const ENCRYPTION_KEY = process.env.REACT_APP_CRYJS; // Replace with a secure encryption key

export const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(password, ENCRYPTION_KEY).toString();
};

export const decryptPassword = (encryptedPassword) => {
  const bytes = CryptoJS.AES.decrypt(encryptedPassword, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};
export const formatDate = (dateStr) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const date = new Date(dateStr);
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};
export const formatDateHypen = (dateStr) => {
  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const period = date.getHours() < 12 ? 'am' : 'pm';

  const formattedDate = `${month}-${day}-${year} ${hour}:${minute} ${period}`;
  return formattedDate;
};
export const formatDateWithSeconds = (dateStr) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const date = new Date(dateStr);
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

export const formatCurrency = (value) =>
  value.toLocaleString("en-US", { style: "currency", currency: "USD" });

export const findCryptoDecimal = (val, symbol) => {
  if (symbol === "BTC") {
    return val * 100000000;
  } else if (symbol === "ETH") {
    return val * 1000000000000000000;
  } else if (symbol === "BCH") {
    return val * 100000000;
  } else if (symbol === "LTC") {
    return val * 100000000;
  } else if (symbol === "CAD") {
    return val * 100;
  }
};
export const findCadDecimal = (val, symbol) => {
  if (symbol === "BTC" || symbol.includes("BTC")) {
    return `${val / 100000000}`;
  } else if (symbol === "ETH" || symbol.includes("ETH")) {
    return `${(val / 1000000000000000000)?.toFixed(8)}`;
  } else if (symbol === "BCH" || symbol.includes("BCH")) {
    return `${val / 100000000}`;
  } else if (symbol === "LTC" || symbol.includes("LTC")) {
    return `${val / 100000000}`;
  } else if (symbol === "CAD" || symbol.includes("CAD")) {
    return `${(val / 100).toFixed(2)}`;
  }
};
export const renderAddress = (address) => {
  return address ? `${address.slice(0, 4)}...${address.slice(-4)}` : "";
};
export const findNameForCrypto = (symbol) => {
  if (symbol === "BTC" || symbol.includes("BTC")) {
    return (
      <>
        <img src={bitcoinLogo} alt="" className="me-2" width="24" />
        Bitcoin
      </>
    );
  } else if (symbol === "ETH" || symbol.includes("ETH")) {
    return (
      <>
        <img src={ethereumLogo} alt="" className="me-2" width="24" />
        Ethereum
      </>
    );
  } else if (symbol === "BCH" || symbol.includes("BCH")) {
    return (
      <>
        <img src={bitcoincashLogo} alt="" className="me-2" width="24" />
        Bitcoin Cash
      </>
    );
  } else if (symbol === "LTC" || symbol.includes("LTC")) {
    return (
      <>
        <img src={litecoinLogo} alt="" className="me-2" width="24" />
        Litecoin
      </>
    );
  } else if (symbol === "CAD" || symbol.includes("CAD")) {
    return (
      <>
        <img src={cadLogo} alt="" className="me-2" width="24" />
        Canadian Dollar
      </>
    );
  }
};
export const copyaddress = (adr) => {
  // console.log(adr);
  const textArea = document.createElement('textarea');
  textArea.value = adr;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
  // dispatch(commonSuccess('Text copied to clipboard'));
}