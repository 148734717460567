import axios from 'axios';
import { config } from '../config';

const apiWithJwt = (bearerToken) => axios.create({
  baseURL: config.CYBRID_API,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearerToken}`
  },
});

export default apiWithJwt;