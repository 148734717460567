import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { commonError } from "../actions/customerActions";
import { useDispatch } from "react-redux";
import { decryptPassword } from "../utils/Encryption";
import { types } from "../store/constants/types";
import apiAdminWithJwt from "../services/apiAdminWithJwt";
import EmptyLayout from "./pages/emptyLayout";
import apiSignUp from "../services/apiSignUp";

export const ProtectedRoute = ({ redirectPath = "/signin", children }) => {
  const dispatch = useDispatch();

  let EncToken = sessionStorage.getItem("guid");
  let guid = EncToken && decryptPassword(EncToken);

  
  // if there is accessToken present split it and find token Expiration
  const accessToken = sessionStorage.getItem("at");
  const accessTokenData = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) : null;
  const accessTokenExpiration = accessTokenData && accessTokenData.exp * 1000;
  // if there is userToken present split it and find token Expiration
  const userToken = sessionStorage.getItem("ut");
  const userTokenData = userToken ? JSON.parse(atob(userToken.split(".")[1])) : null; // Decode the payload
  const userTokenExpiration = userTokenData && userTokenData.exp * 1000; // Convert to milliseconds
  const userTokenIAT = userTokenData && userTokenData.iat;

  const currentTime = Date.now();

  const [loading, setLoading] = useState(false);

  const [vaildStatus, setVaildStatus] = useState();

  useEffect(()=>{
    const checkCustomerTokenDublicate = async()=>{
      try{
        const userEmail = sessionStorage.getItem("user_email");
        const res = await apiSignUp.post("/customers/check-customer-token-dublicate", { email: userEmail});
        const current_user_iat = res.data.data.iat;        
        if(userTokenIAT == current_user_iat){
          setVaildStatus(true);
        } else {
          setVaildStatus(false);
        }
        } catch(error){
          dispatch({ type: types.POST_CUSTOMERTOKEN_FAILURE, payload: error });          
        }
    }
    checkCustomerTokenDublicate();
  })

  const customerToken = async () => {
    if (accessTokenExpiration < currentTime) {
      // if accessToken is expired call to recreate accesstoken
      const apiWithToken = apiAdminWithJwt(userToken);
      try {
        const res = await apiWithToken.post("/customers/regenerate-customer-token", { cybrid_guid: guid });
        if (res.status === 200) {
          sessionStorage.setItem("at", res?.data?.data?.at);
          setLoading(false);
        }
        return;
      } catch (error) {
        dispatch({ type: types.POST_CUSTOMERTOKEN_FAILURE, payload: error });
        setLoading(false);
      }
    }
  };

  if (!accessToken || accessToken === null || userToken === null || !userToken) {
    //if any tokenToken doesn't exist
    return <Navigate to={redirectPath} replace />;
  } else if (userTokenExpiration < currentTime) {
    sessionStorage.removeItem("ut");
    sessionStorage.removeItem("at");
    sessionStorage.removeItem("user_email");
    sessionStorage.removeItem("guid");
    localStorage.removeItem("f_at");
    localStorage.removeItem("displayName");
    dispatch(commonError("Your session has expired. Please log in again."));
    return <Navigate to="/signin" />
  }
  else {
    if (accessTokenExpiration < currentTime) {
      // Condition 1: Token exists and has expired
      if (!loading) {
        // Function call to Regenerate customer access accessToken "at"
        customerToken();
        setLoading(true);
      }else {
        return <EmptyLayout/>
      }
    } else if (vaildStatus === false){
      sessionStorage.removeItem("ut");
      sessionStorage.removeItem("at");
      sessionStorage.removeItem("user_email");
      sessionStorage.removeItem("guid");
      localStorage.removeItem("f_at");
      localStorage.removeItem("displayName");
      dispatch(commonError("Your session has expired. Please log in again."));
      return <Navigate to="/signin" />
    } else {
      // Condition 2: Token exists and has not expired       
      if (!loading) {
        return children;        
      } 
    }
  }
};


// If user data exists, render the children (protected component)
export const AuthenticateRoute = ({ redirectPath = "/dashboard", children }) => {  
  // Check if user data exists in local storage
  const accessToken = sessionStorage.getItem("at");
  const userToken = sessionStorage.getItem("ut");

  if (accessToken && userToken) {
    return <Navigate to={redirectPath} replace />;
  }

  // if (!token || !jwtToken) {
  //   dispatch(commonError("Your session has expired. Please log in again."));
  // }

  // If user data exists, render the children (protected component)
  return children;
};

 
