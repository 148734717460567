import axios from 'axios';
import { config } from '../config';

const apiAdminWithJwt = (bearerToken) => axios.create({
  baseURL: config.BACKEND_API,
  headers: {
    'Content-Type': 'application/json',
    'authorization': `Bearer ${bearerToken}`
  },
});

export default apiAdminWithJwt;